import React, { useState } from 'react';
import styled from 'styled-components';
import { ValuesListData } from 'components/variables/values';
import Swiper from 'react-id-swiper';
// import "swiper/swiper.min.css";
// import "swiper/components/pagination/pagination.min.css";
import { Autoplay, Swiper as RealSwiper } from "swiper";
RealSwiper.use([Autoplay]);
import SwiperCore, { Pagination, Navigation } from "swiper";
SwiperCore.use([Pagination, Navigation]);

const ListWrap = styled.ul<{ isView: boolean }>`
  margin-top: 60px;
  max-width: 690px;
  li {
    background-color: #2c2c2c;
    .about-wrap {
      padding: 50px;
      color: #fff;
      .title {
        font-size: 4rem;
        letter-spacing: 0.075em;
        font-family: 'Marcellus',serif;
      }
      .title-sub {
        margin: 10px 0 40px;
        font-size: 1.4rem;
        letter-spacing: 0.2em;
      }
      .text {
        font-size: 1.4rem;
        letter-spacing: 0.2em;
        color: #a5a5a5;
      }
    }
  }
  .swiper-container {
    display: none;
    ${props => props.isView && 'display: block;'}
    position: relative;
    padding-bottom: 80px;
    overflow: hidden;
    .swiper-wrapper {
      .swiper-slide {
        height: auto;
      }
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      content: '→';
      bottom: 5px;
      font-size: 22px;
      font-family: unset;
      color: #fff;
    }
    .swiper-button-next,
    .swiper-button-prev {
      bottom: 22px;
    }
    .swiper-button-next {
      top: unset;
      right: 115px;
      width: 30px;
      height: 30px;
      &:before {
        content: '/';
        position: absolute;
        left: -10px;
        font-size: 1.7rem;
        color: #fff;
      }
    }
    .swiper-button-prev {
      top: unset;
      left: calc(100% - 187px);
      width: 30px;
      height: 30px;
      &:after {
        content: '←';
      }
    }
    .swiper-pagination-fraction {
      text-align: right;
      font-size: 2rem;
      letter-spacing: 0.01em;
      font-family: 'Marcellus',serif;
      color: #fff;
      .swiper-pagination-current {
        font-size: 4.4rem;
      }
      .swiper-pagination-total {

      }
    }
  }
  ${props => props.theme.media.tab} {
    margin-top: 50px;
    li {
      .about-wrap {
        padding: 30px;
        .title {
          font-size: 3.5rem;
        }
        .title-sub,
        .text {
          font-size: 1.5rem;
        }
      }
    }
    .swiper-container {
      padding-bottom: 60px;
      .swiper-button-next,
      .swiper-button-prev {
        right: 90px;
        bottom: 15px;
      }
      .swiper-button-next {
        right: 90px;
      }
      .swiper-button-prev {
        left: calc(100% - 165px);
      }
      .swiper-pagination-fraction {
        .swiper-pagination-current {
          font-size: 3rem;
        }
        .swiper-pagination-total {
          font-size: 1.4rem;
        }
      }
    }
  }
  ${props => props.theme.media.sp} {
    li {
      .about-wrap {
        padding: 20px;
      }
    }
  }
`;

const SimpleSwiperWithParams = () => {
  const [isViewSwiper, setIsViewSwiper] = useState(false);
  const params = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
      clickable: true,
      formatFractionCurrent: function (number: string) {
        if (Number(number) < 10) {
          return '0' + number;
        } else {
          return number;
        }
      },
      formatFractionTotal: function (number: string) {
        if (Number(number) < 10) {
          return '0' + number;
        } else {
          return number;
        }
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerView: 1,
    spaceBetween: 10,
  }

  setTimeout(() => {
    setIsViewSwiper(true);
  }, 100);

  return (
    <ListWrap isView={isViewSwiper}>
      <Swiper {...params}>
        {ValuesListData.map((item, index) => (
          <li key={item.no}>
            <div className="about-wrap">
              <p className="title">{item.title}</p>
              <p className="title-sub">{item.titleSub}</p>
              <p className="text">{item.text}</p>
            </div>
          </li>
        ))}
      </Swiper>
    </ListWrap>
  )
}

export default SimpleSwiperWithParams;