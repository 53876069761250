import React from 'react';
import styled from 'styled-components';
import Slide from 'components/LV1/SlideTop';
import SlideText from 'components/LV1/AosSlideText';
import Aos from 'components/LV1/Aos';

const Wrap = styled.div`
  padding: 150px 0 100px;
  background-color: #faf7f4;
  ${props => props.theme.media.tab} {
    padding: 90px 0 60px;
  }
`;

const Inner = styled.div`
  // display: flex;
  // flex-wrap: wrap;
  margin: auto;
  max-width: 1400px;
  padding: 0 15px;
  ${props => props.theme.media.tab} {
    display: block;
    max-width: 510px;
  }
`;

const Left = styled.div`
  position: relative;
  z-index: 1;
  // width: 520px;
  width: 100%;
  text-align: center;
  margin-top: 70px;
  // margin-left: 100px;
  // margin-right: 30px;
  // ${props => props.theme.media.max} {
  //   width: 420px;
  //   margin-left: 50px;
  // }
  // ${props => props.theme.media.pc} {
  //   width: 365px;
  //   margin-left: 35px;
  //   margin-right: 15px;
  // }
  ${props => props.theme.media.tab} {
    width: 100%;
    margin: auto;
    margin-top: 35px;
    padding: 0 15px;
  }
  .scroll-link {
    position: absolute;
    left: 0;
    bottom: -180px;
    padding: 0 10px 190px;
    color: #000;
    font-size: 12px;
    font-family: 'Marcellus', serif;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-decoration: none;
    writing-mode: vertical-lr;
    ${props => props.theme.media.pc} {
      padding: 0 10px 160px;
    }
    ${props => props.theme.media.tab} {
      display: none;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 230px;
      background: #000;
      animation: sdl 2s cubic-bezier(1, 0, 0, 1) infinite;
      ${props => props.theme.media.pc} {
        height: 200px;
      }
    }
    @keyframes sdl {
      0% {
        transform: scale(1, 0);
        transform-origin: 0 0;
      }
      50% {
        transform: scale(1, 1);
        transform-origin: 0 0;
      }
      50.1% {
        transform: scale(1, 1);
        transform-origin: 0 100%;
      }
      100% {
        transform: scale(1, 0);
        transform-origin: 0 100%;
      }
    }
  }
`;

const Title = styled.h1`
  font-family: 'Marcellus', serif;
  font-size: 8.4rem;
  letter-spacing: 0.075em;
  line-height: 1.1;
  white-space: nowrap;
  ${props => props.theme.media.max} {
    font-size: 6.4rem;
  }
  ${props => props.theme.media.pc} {
    font-size: 5.4rem;
  }
  ${props => props.theme.media.tab} {
    font-size: 4.5rem;
  }
  ${props => props.theme.media.sp} {
    font-size: 4.4rem;
  }
`;

const Description = styled.div`
  margin-top: 40px;
  font-size: 1.6rem;
  letter-spacing: 0.2em;
  line-height: 2.2;
  div {
    margin: auto;
  }
  ${props => props.theme.media.tab} {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: left;
    br.only-pc {
      display: none;
    }
  }
`;

const Right = styled.div`
  position: relative;
  width: calc(100% - 660px);
  max-width: 760px;
  z-index: 1;
  ${props => props.theme.media.max} {
    width: calc(100% - 520px);
  }
  ${props => props.theme.media.pc} {
    width: calc(100% - 435px);
  }
  ${props => props.theme.media.tab} {
    width: 100%;
    margin: auto;
    padding: 0 15px;
  }
`;

const TopView: React.FC = () => {
  return (
    <Wrap>
      <Inner>
        <Left>
          <Title>
            <SlideText isInline>
              Co-Creation
            </SlideText>
            <br />
            <SlideText delay="200" isInline>
              Laboratory
            </SlideText>
          </Title>
          <Description>
            <SlideText delay="1000" duration="1400">
              コクラボはスピード・品質を重視した
              <br className="only-pc" />
              ワンストップ開発を強みとしているIT企業です。
            </SlideText>
            <SlideText delay="1200" duration="1400">
              <br />
              スタートアップ・ベンチャーからエンタープライズ企業まで、
              <br className="only-pc" />
              さまざまな種類のWebサイトやWebサービスを企画・開発しています。
              <br className="only-pc" />
              クリエイティブ・マーケティング・広告・データ分析など開発以外の領域も対応可能です。
              <br />
              <br />
            </SlideText>
            <SlideText delay="1400" duration="1400">
              Developmentに真摯に向き合う職人気質なメンバーが揃う
              <br className="only-pc" />
              フルスタックなエンジニア・デザイナー組織です。
            </SlideText>
          </Description>
          <div className="scroll-link">Scroll</div>
        </Left>
      </Inner>
    </Wrap>
  );
};
export default TopView;
