export const ValuesListData = [
  { no: '01', title: 'Needs priority.', titleSub: 'ニーズ優先', text: 'お客様のニーズに合致したサービスやプロダクトを提供するために必要なことを常に考え、行動します。', },
  { no: '02', title: 'Creation of new value.', titleSub: '新たな価値の創造', text: 'パイオニア精神を持ち行動することで、お客さまのニーズを常に先取りし、新たな価値を創造します。', },
  { no: '03', title: 'Keep trying.', titleSub: '挑戦し続ける', text: '新たな価値を創造し続けるために、情熱を持ち、何事にも全力で挑戦し続けます。一人ひとりが自らを高め、創造的で自由闊達な活力ある集団であり続けます。', },
  { no: '04', title: 'Speed.', titleSub: 'スピード', text: '時間は有限で貴重です。物事の優先度を常に考え、スピード感を持って行動します。過剰な完成度よりスピードを優先します。', },
  { no: '05', title: 'Think positive.', titleSub: '前向きに考える', text: '問題や課題があっても、目的達成に向けて前向きに考えます。達成できない理由を考えるよりも、どうしたら問題が解決できるか、目的を達成できるかを考えます。', },
  { no: '06', title: 'Flexible thinking.', titleSub: '柔軟な思考', text: '固定観念を捨て、物事を常に多角的に考え、臨機応変かつ確実にアプローチします。', },
  { no: '07', title: 'Be humble in all things.', titleSub: '何事も謙虚に', text: '年齢や立場、状況に関係なく、過信せず謙虚な姿勢で物事に取り組みます。常に相手を尊重し、傾聴のスタンスで行動します。', },
  { no: '08', title: 'Fairness.', titleSub: '公明正大', text: '高い倫理観を常に持ち行動し、法令・規則を遵守するとともに、公正で透明な企業活動を実践します。', },
  { no: '09', title: 'Have fun!', titleSub: '全力で楽しむ', text: '私たち自身が全力で楽しみます。全力で楽しむことでさまざまな経験を積むことができ、多くのものを吸収することができます。それらは新たな価値としてお客様に提供できると考えています。', },
];