import React, { Fragment } from 'react';
import Head from 'components/LV1/Seo';
import Top from 'components/LV3/Top';

const TopPage: React.FC = () => (
  <Fragment>
    <Head />
    <Top />
  </Fragment>
);
export default TopPage;
