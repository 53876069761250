import React from 'react';
import styled from 'styled-components';
import { Path } from 'components/variables/path';
import { ServicesListData } from 'components/variables/services';
import SectionTitle from 'components/LV1/SectionTitle';
import TextCircleButton from 'components/LV1/TextCircleButton';
import SlideText from 'components/LV1/AosSlideText';
import OurServiceList from 'components/LV2/Lists/OurServiceList';
import Aos from 'components/LV1/Aos';

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  padding: 130px 15px;
  background-color: #efe9e6;
  ${props => props.theme.media.tab} {
    padding: 65px 15px;
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 76px;
  ${props => props.theme.media.tab} {
    display: block;
  }
`;

const Description = styled.div`
  font-size: 1.6rem;
  letter-spacing: 0.18em;
  margin-left: 70px;
  padding-left: 70px;
  border-left: 1px solid #000;
  ${props => props.theme.media.pc} {
    margin-left: 50px;
    padding-left: 50px;
  }
  ${props => props.theme.media.tab} {
    margin-top: 50px;
    margin-left: 0;
    padding-left: 0;
    border-left: 0;
  }
`;

const ViewMoreWrap = styled.div`
  margin: 60px auto 0;
`;

const OurService: React.FC = () => {
  return (
    <Wrap>
      <Inner>
        <Header>
          <SectionTitle
            title="Our Service"
            titleSub="サービス"
          />
          <Description>
            <SlideText>
              ITに関係するさまざまなサービスを提供しています。
              <br />
              コンサルティングを含む要件定義から運用保守まで、開発全行程を多様な技術を利用し一気通貫で対応することが可能です。
              <br />
              開発案件のほか、ITを有効活用するためのコンサルティングや技術的なサポートなども行っています。
            </SlideText>
          </Description>
        </Header>
        <OurServiceList
          list={[
            {
              num: ServicesListData[0].no,
              title: ServicesListData[0].title,
              titleSub: ServicesListData[0].titleSub,
              text: ServicesListData[0].text,
              delay: "0",
            },
            {
              num: ServicesListData[1].no,
              title: ServicesListData[1].title,
              titleSub: ServicesListData[1].titleSub,
              text: ServicesListData[1].text,
              delay: "150",
            },
            {
              num: ServicesListData[2].no,
              title: ServicesListData[2].title,
              titleSub: ServicesListData[2].titleSub,
              text: ServicesListData[2].text,
              delay: "300",
            },
          ]}
        />
      </Inner>
      <ViewMoreWrap>
        <Aos>
          <TextCircleButton
            link={Path.service}
            text="VIEW MORE"
            center
            boarderBlack
          />
        </Aos>
      </ViewMoreWrap>
    </Wrap>
  );
};
export default OurService;
