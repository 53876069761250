import React from 'react';
import TopView from 'components/LV2/TopView';
import OurService from 'components/LV2/OurService';
import AboutTop from 'components/LV2/AboutTop';

const Top: React.FC = () => {
  return (
    <div>
      <TopView />
      <OurService />
      <AboutTop />
    </div>
  );
};
export default Top;
