import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div<{ center: boolean }>`
  white-space: nowrap;
  ${props => props.center && 'text-align: center;'}
  p {
    &.main {
      font-size: 5.4rem;
      letter-spacing: 0.1em;
      font-family: 'Marcellus', serif;
      clip-path: inset(0 100% 0 0);
      display: inline-block;
      transition: 1.2s cubic-bezier(0.37, 0, 0.63, 1);
      transition-property: clip-path;
      &.aos-animate {
        clip-path: inset(0);
      }
    }
    &.sub {
      font-size: 1.4rem;
      letter-spacing: 0.2em;
      clip-path: inset(0 100% 0 0);
      width: fit-content;
      display: block;
      transition: 1.4s cubic-bezier(0.37, 0, 0.63, 1);
      transition-property: clip-path;
      ${props => props.center && 'margin: auto;'}
      &.aos-animate {
        clip-path: inset(0);
      }
    }
  }
  ${props => props.theme.media.tabSp} {
    p {
      &.main {
        font-size: 4.4rem;
      }
      &.sub {
        font-size: 1.2rem;
      }
    }
  }
  ${props => props.theme.media.min} {
    p {
      &.main {
        font-size: 3.9rem;
      }
    }
  }
`;

type Props = {
  title: string,
  titleSub: string,
  center?: boolean,
};

const SectionTitle: React.FC<Props> = props => {
  return (
    <Wrap center={!!props.center}>
      <p className="main" data-aos="fade-in" data-aos-once="false" data-aos-easing="ease-in-out">{props.title}</p>
      <p className="sub" data-aos="fade-in" data-aos-once="false" data-aos-easing="ease-in-out">/&nbsp;{props.titleSub}</p>
    </Wrap>
  );
};
export default SectionTitle;
