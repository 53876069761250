import React from 'react';
import styled from 'styled-components';
import Aos from 'components/LV1/Aos';

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const Card = styled.li`
  position: relative;
  width: calc(33.3333333333% - 20px);
  &:nth-child(2){
    margin: 0 30px;
  }
  .aos-init {
    height: 100%;
    padding: 87px 40px 66px;
    background-color: #fff;
  }
  p {
    font-size: 1.4rem;
    letter-spacing: 0.2em;
    &.card-number {
      position: absolute;
      top: -35px;
      font-size: 4.8rem;
      letter-spacing: 0.1em;
      font-family: 'Marcellus', serif;
    }
    &.card-title {
      font-size: 2.6rem;
      letter-spacing: 0.075em;
      font-family: 'Marcellus', serif;
      text-align: center;
    }
    &.card-title-sub {
      margin: 40px auto;
      text-align: center;
    }
  }
  ${props => props.theme.media.pc} {
    .aos-init {
      padding: 60px 20px 33px;
    }
    p {
      &.card-title {
        font-size: 1.7rem;
      }
      &.card-title-sub {
        margin: 30px auto;
      }
    }
  }
  ${props => props.theme.media.tab} {
    width: calc(50% - 7.5px);
    &:nth-child(2) {
      margin: auto auto auto 15px;
    }
    &:nth-child(3) {
      margin: 50px auto 0;
    }
    p {
      &.card-title {
        font-size: 2.3rem;
      }
    }
  }
  ${props => props.theme.media.tabSp} {
    width: 100%;
    &:nth-child(2),
    &:nth-child(3) {
      margin: 50px auto 0;
    }
  }
  ${props => props.theme.media.sp} {
    p {
      &.card-number {
        font-size: 4.4rem;
      }
      &.card-title {
        font-size: 2.3rem;
      }
      &.card-title-sub {
        margin: 20px auto;
      }
    }
  }
`;

type Props = {
  list: {
    num: string;
    title: string;
    titleSub: string;
    text: string;
    delay: string;
  }[];
};

const ServiceList: React.FC<Props> = (props: Props) => {
  return (
    <List>
      {props.list.map((item, index) => (
        <Card key={item.num}>
          <Aos delay={item.delay}>
            <p className="card-number">{item.num}</p>
            <p className="card-title">{item.title}</p>
            <p className="card-title-sub">{item.titleSub}</p>
            <p>{item.text}</p>
          </Aos>
        </Card>
      ))}
    </List>
  );
};
export default ServiceList;
