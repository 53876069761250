import React from 'react';
import styled from 'styled-components';
import { Path } from 'components/variables/path';
import Aos from 'components/LV1/Aos';
import TextCircleButton from 'components/LV1/TextCircleButton';
import SlideAboutTop from 'components/LV1/SlideAboutTop';
import BgRight from "src/images/common/footer-about-bg-right.jpg";
import BgLeft from "src/images/common/footer-about-bg-left.jpg";

const Wrap = styled.div`
  position: relative;
  z-index: 1;
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
`;

const Left = styled.div`
  width: 35%;
  background-image: url(${BgLeft});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  ${props => props.theme.media.tab} {
    display: none;
  }
`;

const Right = styled.div`
  width: 65%;
  padding: 140px;
  background-image: url(${BgRight});
  background-position: top left;
  background-size: cover;
  .title-wrap {
    color: #fff;
    display: flex;
    align-items: baseline;
    .title {
      font-size: 5.4rem;
      letter-spacing: 0.1em;
      font-family: 'Marcellus', serif;
      clip-path: inset(0 100% 0 0);
      display: inline-block;
      transition: 1.2s cubic-bezier(0.37, 0, 0.63, 1);
      transition-property: clip-path;
      &.aos-animate {
        clip-path: inset(0);
      }
    }
    .title-sub {
      font-size: 1.4rem;
      letter-spacing: 0.2em;
      margin-left: 20px;
      clip-path: inset(0 100% 0 0);
      width: fit-content;
      display: block;
      transition: 1.4s cubic-bezier(0.37, 0, 0.63, 1);
      transition-property: clip-path;
      &.aos-animate {
        clip-path: inset(0);
      }
    }
  }
  .description-wrap {
    margin: 60px 0 50px;
    p {
      letter-spacing: 0.2em;
      color: #fff;
      &.title {
        font-size: 2.8rem;
        font-weight: 300;
        clip-path: inset(0 100% 0 0);
        display: inline-block;
        transition: 1.2s cubic-bezier(0.37, 0, 0.63, 1);
        transition-property: clip-path;
        &.aos-animate {
          clip-path: inset(0);
        }
      }
      &.sub {
        margin-top: 15px;
        font-size: 1.6rem;
        clip-path: inset(0 100% 0 0);
        width: fit-content;
        display: block;
        transition: 1.4s cubic-bezier(0.37, 0, 0.63, 1);
        transition-property: clip-path;
        &.aos-animate {
          clip-path: inset(0);
        }
      }
    }
  }
  ${props => props.theme.media.max} {
    padding: 100px 70px;
  }
  ${props => props.theme.media.tab} {
    width: 100%;
    padding: 60px 15px;
    .title-wrap {
      display: block;
      .title {
        font-size: 4.4rem;
      }
      .title-sub {
        margin-top: 10px;
        margin-left: 0;
        font-size: 1.3rem;
      }
    }
    .description-wrap {
      margin: 50px 0 40px;
      p {
        &.title {
          font-size: 2.3rem;
        }
        &.sub {
          font-size: 1.5rem;
        }
      }
    }
  }
`;

const ViewMoreWrap = styled.div`
  margin: 45px auto 0;
  ${props => props.theme.media.tab} {
    margin: 35px auto 0;
  }
`;

const AboutTop: React.FC = () => {
  return (
    <Wrap>
      <Inner>
        <Left>
        </Left>
        <Right>
          <div className="title-wrap">
            <p className="title" data-aos="fade-in" data-aos-once="false" data-aos-easing="ease-in-out">
              About
            </p>
            <p className="title-sub" data-aos="fade-in" data-aos-once="false" data-aos-easing="ease-in-out">
              / わたしたちについて
            </p>
          </div>
          <div className="description-wrap">
            <p className="title" data-aos="fade-in" data-aos-once="false" data-aos-easing="ease-in-out">ITを通じて、より豊かな未来を創造する。</p>
            <p className="sub" data-aos="fade-in" data-aos-once="false" data-aos-easing="ease-in-out">コクラボが大切にしている９つの価値観</p>
            <ViewMoreWrap>
              <Aos>
                <TextCircleButton
                  link={Path.about}
                  text="VIEW MORE"
                  white
                />
              </Aos>
            </ViewMoreWrap>
          </div>
          <Aos>
            <SlideAboutTop />
          </Aos>
        </Right>
      </Inner>
    </Wrap>
  );
};
export default AboutTop;
